import difference from 'lodash/difference';
import { vsprintf } from 'sprintf-js';
import { AppLabels } from 'utils/constants';

const USER_AUTH_ERROR = 'unable_to_retrieve_users';
const MISSING_INTEGRATION_ERROR = 'missing_integration';

/**
 * @param {Object} error
 * @return {String}
 */
const formatError = error => {
	const linkMessage = `<a href="/account/integrations">Please reconnect ${AppLabels[error.integration] ?? ''}</a>`;

	switch (error.error) {
		case USER_AUTH_ERROR:
			return `Authentication error. ${linkMessage}`;
		case MISSING_INTEGRATION_ERROR:
			return `Integration missing. ${linkMessage}`;
		default:
			return error.message;
	}
};

/**
 * @param {Object} error
 * @return {String}
 */
export function mapErrorMessage(error) {
	const {
		message,
		messageArguments
	} = error;

	let errorMessage = formatError(error);

	if (messageArguments) {
		errorMessage = vsprintf(message, messageArguments);
	}

	return `<span>${errorMessage}</span>`;
}

/**
 * @param {Array} errors
 * @param {Array} contexts
 * @return {Objext}
 */
export function groupErrors(errors, contexts) {
	const generalErrors = errors
		.filter(error => error)
		.filter(
			error => !contexts || !contexts.includes(error.context) || error.messageArguments
		);

	const contextualErrors = difference(errors, generalErrors);

	return {
		generalErrors,
		contextualErrors
	};
}

/**
 * Base class for API errors.
 */
export class BaseException extends Error {
	/**
	 * @param {Object} result
	 */
	constructor(result) {
		super(result);

		this.errors = result.errors;
		this.message = result.errors.reduce(
			(message, error) => `${message}\n ${error.name} ${error.error} ${error.message}`, ''
		);
	}
}
