export const getInitialState = () => ({
	connections: [],
	generalErrors: [],
	apps: [],
	isUpgradeDialogOpen: false,
	isEngagementSyncUpgradeDialogOpen: false,
	isContactSyncConfirmationDialogOpen: false,
	isEngagementSyncConfirmationDialogOpen: false,
	isSplitContactSyncConfirmationDialogOpen: false,
	isSplitEngagementSyncConfirmationDialogOpen: false,
	options: {},
	contactCreationRules: {},
	areTwoWaySyncErrorsVisible: false
});
